import { useEffect, useRef, useState } from "react";
import "./dashboard.css";
import { Button, Modal } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router";

function Dashboard({ login }) {
  const [data, setData] = useState({
    user: "-",
    orgs: "-",
    events: "-",
    active: "-",
    tokens: "-",
  });
  // const [login, setLogin] = useState(false);
  const navigate = useNavigate();
  // const firstupdate = useRef(true);
  useEffect(() => {
    // if (firstupdate.current) {
    //   firstupdate.current = false;
    //   return;
    // }

    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      console.log("Hello all 3");
      return navigate("");
    }

    const formatdata = (data, items, key) => {
      const newitems = data[key].reduce((acc, current, ind) => {
        const { mintnetwork, total } = current;
        acc.push({ ...items[ind], network: mintnetwork, [key]: total });
        return acc;
      }, []);
      return newitems;
    };

    const getdata = async () => {
      await fetch("https://api.clifty.io/admin/dashboardCounts", {
        headers: {
          Authorization: `Bearer ${user.sessionToken}`,
        },
      })
        .then((res) => res.json())
        .then(async (data1) => {
          let newtokens = await formatdata(data1.tokens, [], "issued");
          // console.log(newtokens);
          newtokens = await formatdata(data1.tokens, newtokens, "collected");
          setData({
            user: data1.users.total,
            orgs: data1.orgs.total,
            events: data1.events.total,
            active: data1.events.active,
            tokens: newtokens,
          });
        });
    };
    // setLogin(!!user);
    getdata();
  }, []);

  useEffect(() => {
    // console.log(data);
  }, [data]);
  const Navigate = useNavigate();
  const handlenavigate = (page) => navigate(`/dashboard/${page}`);
  return (
    <>
      <div className="container">
        <h1>Dashboard</h1>
        <div className="contents">
          <div className="row mb-4">
            <div className="col-sm-8">
              <div
                className="card pl-5"
                onClick={() => handlenavigate("users")}
              >
                <div className="card-body">
                  <h5 className="card-title">Total Users</h5>
                  <p className="card-text" style={{ color: "blue" }}>
                    {data.user}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-sm-8">
              <div className="card pl-5" onClick={() => handlenavigate("orgs")}>
                <div className="card-body">
                  <h5 className="card-title">Total Orgs</h5>
                  <p className="card-text">{data.orgs}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-sm-8">
              <div
                className="card pl-5"
                onClick={() => handlenavigate("events")}
              >
                <div className="card-body">
                  <h5 className="card-title">Total Events</h5>
                  <p className="card-text">{data.events}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8">
              <div
                className="card pl-5"
                onClick={() => handlenavigate("activeevents")}
              >
                <div className="card-body">
                  <h5 className="card-title">Active Events</h5>
                  <p className="card-text">{data.active}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Tokens Issued</h5>
                </div>
                <ul className="list-group list-group-flush">
                  {typeof data.tokens === "string" ? (
                    <li className="list-group-item">{data.tokens}</li>
                  ) : (
                    data.tokens.map((token, index) => (
                      <li
                        key={index}
                        className="list-group-item text-capitalize fw-bold"
                      >
                        {token.network.toLowerCase()} : {token.issued}
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Tokens Accepted</h5>
                </div>
                <ul className="list-group list-group-flush">
                  {typeof data.tokens === "string" ? (
                    <li className="list-group-item">{data.tokens}</li>
                  ) : (
                    data.tokens.map((token, index) => (
                      <li
                        key={index}
                        className="list-group-item text-capitalize fw-bold"
                      >
                        {token.network.toLowerCase()} : {token.collected}
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
