import { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router";

const PrivateRoute = () => {
  const login = JSON.parse(localStorage.getItem("user"));

  return login ? <Outlet /> : <Navigate replace={true} to="/" />;
};

export default PrivateRoute;
