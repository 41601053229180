import logo from "./logo.svg";
import "./App.css";
import "./style.scss";
import Header from "./component/header/header";
import Home from "./component/home/home";
import Footer from "./component/footer/footer";
import Signin from "./component/signin/signin";
import { Route, Routes } from "react-router";
import { useEffect, useState } from "react";
import Dashboard from "./component/Dashboard/dashboard";
import PrivateRoute from "./component/Privateroute/privateroute";
import Card from "./component/card/card";

function App() {
  const [login, setLogin] = useState(false);
  const [popupmsg, setPopupmsg] = useState(null);
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setLogin(true);
    }
  }, []);
  useEffect(() => {}, [login]);
  return (
    <div className="pages">
      <Header setmsg={setPopupmsg} login={login} setLogin={setLogin} />
      {/* <Home /> */}
      <div className="route">
        <Routes>
          <Route
            exact
            path=""
            element={
              <Signin message={popupmsg} setLogin={setLogin} login={login} />
            }
          ></Route>
          <Route path="/dashboard" element={<PrivateRoute />}>
            <Route path="" element={<Dashboard login={login} />}></Route>
            <Route path="/dashboard/:name" element={<Card />}></Route>
          </Route>
          {/* <Route
            exact
            path="/dashboard/:name"
            element={
              <PrivateRoute login={login} setmsg={setPopupmsg}>
                <Card />
              </PrivateRoute>
            }
          ></Route> */}
          <Route exact path="/home" element={<Home />}></Route>
        </Routes>
      </div>

      <Footer />
    </div>
  );
}

export default App;
